// eslint-disable-next-line no-restricted-imports
import { TLD } from '@peloton/app-config';
import { isBrowser } from '../../browser';
import { DEFAULT_LOCALE, isRouteSegmentLocale } from './path';
import { LOCALE_TOGGLE } from './toggle';

export enum Country {
  UnitedStates = 'us',
  UnitedKingdom = 'gb',
  Canada = 'ca',
  Germany = 'de',
  Australia = 'au',
  Austria = 'at',
  Default = 'us',
}

export const tldCountriesMap: Record<string, Country> = {
  '.com': Country.UnitedStates,
  '.co.uk': Country.UnitedKingdom,
  '.ca': Country.Canada,
  '.de': Country.Germany,
  '.com.au': Country.Australia,
  '.at': Country.Austria,
};

export const localeCountriesMap: Record<string, Country> = {
  us: Country.UnitedStates,
  gb: Country.UnitedKingdom,
  ca: Country.Canada,
  de: Country.Germany,
  au: Country.Australia,
  at: Country.Austria,
};

export const parseLocale = (locale: string) => {
  return locale ? locale.replace(/^[A-z]*-/, '').toLowerCase() : '';
};

export const toCountryFromLocale = (locale: string) => {
  const key = Object.keys(localeCountriesMap).find(
    countryLocale => parseLocale(locale) === countryLocale,
  );
  return localeCountriesMap[key ?? ''] ?? Country.Default;
};

export const toCountryFromString = (country: string, dontReturnDefault?: boolean) => {
  const key = Object.keys(localeCountriesMap).find(c => c === country.toLowerCase());
  const typedCountry = localeCountriesMap[key ?? ''];
  if (dontReturnDefault) {
    return typedCountry;
  }
  return typedCountry ?? Country.Default;
};

/**
 * This will return Country based on App Config provided TLD, if available.
 * Falls back to `window.location.hostname`, if not.
 */
export const toCountryFromTLD = (
  hostname: string = TLD ?? (isBrowser ? window.location.hostname : ''),
): Country => {
  const key = Object.keys(tldCountriesMap).find(tld => hostname.endsWith(tld));
  return tldCountriesMap[key ?? ''] ?? Country.Default;
};

export const toCountry = (
  hostname: string = TLD ?? (isBrowser ? window.location.hostname : ''),
): Country => {
  const browserLocale = isBrowser
    ? window.location.pathname?.split('/')[1]
    : DEFAULT_LOCALE;

  if (isBrowser && LOCALE_TOGGLE && isRouteSegmentLocale(browserLocale)) {
    return toCountryFromLocale(browserLocale);
  }
  return toCountryFromTLD(hostname);
};

export const parseCountry = (country?: string): Country => {
  switch (country && country.toLowerCase()) {
    case 'gb':
    case 'uk':
    case 'united kingdom':
      return Country.UnitedKingdom;
    case 'ca':
    case 'canada':
      return Country.Canada;
    case 'de':
    case 'germany':
      return Country.Germany;
    case 'au':
    case 'australia':
      return Country.Australia;
    case 'at':
    case 'austria':
      return Country.Austria;
    default:
      return Country.UnitedStates;
  }
};
