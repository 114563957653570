export const isBrowser = typeof window === 'object';

export const currentHostname = isBrowser ? window.location.hostname : '';

export const isMsEdge = () =>
  CSS && typeof CSS.supports === 'function' && CSS.supports('-ms-ime-align', 'auto');

export const isIE11 = () =>
  isBrowser && window.navigator.userAgent.indexOf('Trident/') > 0;

export const isIE11OrBelow = () =>
  navigator.userAgent.indexOf('MSIE') > -1 ||
  navigator.userAgent.indexOf('Trident/') > -1;

export enum Device {
  IOS = 'IOS',
  Android = 'Android',
  Other = 'Other',
}

export const toDevice = (userAgent: string = window.navigator.userAgent) => {
  if (isIOS(userAgent)) {
    return Device.IOS;
  } else if (isAndroid(userAgent)) {
    return Device.Android;
  } else {
    return Device.Other;
  }
};

export const isIOS = (userAgent: string = window.navigator.userAgent) =>
  !!userAgent.match(/iPhone|iPod|iPad/);

export const isAndroid = (userAgent: string = window.navigator.userAgent) =>
  !!userAgent.match(/android/i);

// TODO: use https://github.com/omrilotan/isbot
const ROBOT_CHECK = /facebookexternalhit|facebot|prerender|checklyhq/i;
export const isRobot = (
  userAgent: string = isBrowser ? window?.navigator?.userAgent : '',
) => ROBOT_CHECK.test(userAgent);

export const isChrome = () => /chrom/i.test(window.navigator.userAgent ?? '');

export const supportsPositionSticky = () => CSS && CSS.supports('position', 'sticky');
