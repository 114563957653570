import { Country, toCountry, toCountryFromLocale } from './country';
import type { Locale, ContentLocale } from './locale';

interface StringKeyValueObject {
  [key: string]: string;
}

function invertStringKeyValues(obj: StringKeyValueObject): StringKeyValueObject {
  const ret: StringKeyValueObject = {};
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key;
  });
  return ret;
}

export enum Language {
  English = 'en',
  German = 'de',
  Spanish = 'es',
  PseudoLocalization = 'pseudo-localization',
  EnglishBeta = 'uz',
  French = 'fr',
}

export const TLDSupportedLanguages: Record<string, Language[]> = {
  '.com': [Language.English],
  '.co.uk': [Language.English],
  '.ca': [Language.English, Language.French],
  '.de': [Language.German],
  '.com.au': [Language.English],
  '.at': [Language.German],
  '.es': [Language.Spanish],
  '.mx': [Language.Spanish],
};

export const languageCodeToName = invertStringKeyValues(Language);

export const isLanguage = (str: string): str is Language =>
  Object.values(Language).includes(str as Language);

export const toLanguage = (country: Country = toCountry()) => {
  if (country === Country.Germany || country === Country.Austria) {
    return Language.German;
  }
  return Language.English;
};

export const toLanguageFromLocale = (locale: Locale | ContentLocale) => {
  const [language] = locale.split('-');
  return isLanguage(language) ? language : toLanguage(toCountryFromLocale(locale));
};
