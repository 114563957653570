// eslint-disable-next-line no-restricted-imports
import { I18N_LOCALE_TOGGLE } from '@peloton/app-config';
//We need to have this toggle here until we replace all toLocaleFromTLD with useLocale
//Otherwise we don't know if we're called from React component or from server
//And so we don't have a reliable way to get the toggle value
export const LOCALE_TOGGLE = I18N_LOCALE_TOGGLE;

// This is a fix for difference between local behavior of basepath in react-router v5 and deployed
// Dev server adds basepath to all routes, but deployed version doesn't
export const REDIRECTS_ADD_BASEPATH =
  process.env.I18N_NO_BASEPATH_IN_REDIRECTS !== 'local';
